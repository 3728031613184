import React, { useEffect } from "react";
import { MainProps } from "../../props/MainProps";
import { useNavigate } from "react-router-dom";
import { pb } from "../../App";
import { Card, Chip } from "@nextui-org/react";
import { RecordModel } from "pocketbase";

export const DashboardPage: React.FC<MainProps> = () => {
  const isAuthenticated = pb.authStore.isValid;
  const [authData, setAuthData] = React.useState<RecordModel | null>(null); // [1]
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      const fetchData = async () => {};
      fetchData();
    }
  }, []);

  return (
    <div className='w-full h-full p-4 flex flex-col gap-4 bg-white'>
      <h2 className='font-semibold text-lg'>Your Profile 🧍</h2>
      <Card className='w-80 p-4'>
        <p>Welcome Back {pb.authStore.model?.name} 👋</p>
        <p>ID: {pb.authStore.model?.id}</p>
      </Card>
      <h2 className='font-semibold text-lg'>Money Settle Status 💸</h2>

      <Card className='w-full p-4 flex flex-col gap-4'>
        {" "}
        <div className='flex flex-row gap-2 items-center'>
          {" "}
          <Chip>HKD </Chip>
          <h4 className='font-medium text-md'>
            Someone owe you {`60`} dollars.
          </h4>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          {" "}
          <Chip>USD </Chip>
          <h4 className='font-medium text-md'>
            You owe someone {`60`} dollars.
          </h4>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          {" "}
          <Chip>GBP </Chip>
          <h4 className='font-medium text-md'>
            You owe someone {`60`} dollars.
          </h4>
        </div>
      </Card>
      <h2 className='font-semibold text-lg'>Latest Lending Records 😡</h2>

      <Card className='w-full p-4 flex flex-col gap-4'>
        {" "}
        <div className='flex flex-row gap-2 items-center'>
          {" "}
          <Chip>HKD </Chip>
          <h4 className='font-medium text-md'>
            You paid {`60`} dollars for [title] to Kary, Joe, and 3 others.
          </h4>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          {" "}
          <Chip>USD </Chip>
          <h4 className='font-medium text-md'>
            You owe someone {`60`} dollars.
          </h4>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          {" "}
          <Chip>GBP </Chip>
          <h4 className='font-medium text-md'>
            You owe someone {`60`} dollars.
          </h4>
        </div>
      </Card>
    </div>
  );
};
