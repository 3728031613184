import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardPage } from "./pages/dashboard/Dashboard";
import { LoginPage } from "./pages/auth/Login";
import { NavBar } from "./components/Navigation/NavBar";

import { NotFoundPage } from "./pages/others/NotFoundPage";
import { MainProps } from "./props/MainProps";

export const PageWithNavBarPaddingFix: React.FC<MainProps> = ({ children }) => {
  return (
    <div className='w-screen h-[cal(100dvh)] pb-14 pt-12 bg-white'>
      {children}
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path='/dashboard'
        element={
          <App>
            <NavBar title='Dashboard'></NavBar>
            <PageWithNavBarPaddingFix>
              <DashboardPage />
            </PageWithNavBarPaddingFix>
          </App>
        }
      />
      <Route
        path='/'
        element={
          <App>
            <NavBar title='Dashboard' />
            <PageWithNavBarPaddingFix>
              <DashboardPage />
            </PageWithNavBarPaddingFix>
          </App>
        }
      />
      <Route
        path='/login'
        element={<LoginPage />}
      />
      <Route
        path='*'
        element={
          <App>
            <NavBar title='Not Found' /> <NotFoundPage />
          </App>
        }
      />
      ;
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
