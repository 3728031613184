import React, { ReactNode, useEffect } from "react";
import { NextUIProvider } from "@nextui-org/react";
import { MainProps } from "./props/MainProps";
import PocketBase from "pocketbase";
import { useNavigate } from "react-router";

export const pb = new PocketBase("https://per-more.pockethost.io/");

const App: React.FC<MainProps> = ({ children }) => {
  const isAuthenticated = pb.authStore.isValid;
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated == false) {
      navigate("/login");
    }
  }, []);

  return (
    <NextUIProvider>
      {isAuthenticated && (
        <div className='w-screen h-[calc(100dvh)] bg-white'>{children}</div>
      )}
    </NextUIProvider>
  );
};

export default App;
