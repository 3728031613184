import { Button, Spacer } from "@nextui-org/react";
import { useNavigate } from "react-router";

export const NotFoundPage: React.FC = () => {
  const captureCurrentPath = window.location.pathname;
  const navigate = useNavigate();
  return (
    <div className='w-full h-full p-4 flex flex-col gap-8 bg-white items-center justify-center'>
      <h1>{captureCurrentPath.slice(1)} is not accessiable at the moment</h1>

      <iframe
        title='404 json'
        className='rounded-md border-2 border-gray-300 w-40 h-40 md:w-80 md:h-80'
        src='https://lottie.host/embed/540a1c92-49aa-40e4-a9a1-9c51e51c2193/i2zFRnMDhs.json'
      ></iframe>

      <Button
        size='sm'
        variant='shadow'
        color='warning'
        onClick={() => {
          navigate("/dashboard");
        }}
        className='mt-4 w-fit'
      >
        Go Back
      </Button>
    </div>
  );
};
