import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router";
import { PlusIcon } from "@heroicons/react/24/solid";
import { MainProps } from "../../props/MainProps";

interface NavBarProps extends MainProps {
  title: string;
}
export const NavBar: React.FC<NavBarProps> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='w-full h-12 bg-white fixed z-10 border-b'>
        <div className='flex flex-row gap-2 items-center w-full h-full justify-center'>
          <p className='font-bold'>{title}</p>
        </div>
      </div>
      <div className='z-10 bottom-6 flex flex-row fixed w-full px-4 '>
        <div className='flex flex-row gap-4 w-full items-center divide-y-1.5 divide-red-200 py-8 justify-between border-1 border-gray-200 rounded-3xl backdrop-blur-sm bg-white/70 h-14'>
          <div className='flex flex-row gap-0 items-center w-full justify-around'>
            <Button
              size='sm'
              radius='full'
              variant='light'
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Home
            </Button>
            <Button
              size='sm'
              radius='full'
              variant='light'
              onClick={() => {
                navigate("/records");
              }}
            >
              Records
            </Button>
            <button
              className='p-4 -my-8 border-2 border-orange-400 hover:bg-gray-100 rounded-full bg-slate-50'
              onClick={() => {
                navigate("/records/add");
              }}
            >
              <PlusIcon className='w-8 h-8  text-orange-400'></PlusIcon>
            </button>
            <Button
              size='sm'
              radius='full'
              variant='light'
              onClick={() => {
                navigate("/stats");
              }}
            >
              Stats
            </Button>
            <Button
              size='sm'
              radius='full'
              variant='light'
              onClick={() => {
                navigate("/settings");
              }}
            >
              Settings
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
